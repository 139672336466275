import React from "react";
import "./styles.css";

import CtaWpp from "../CTAWpp";

import logo_rodape from "../../assets/logo_rodape.png";
import Pdf from "../../assets/clube-regras.pdf";

const Footer = () => {
  const openPDF = () => {
    window.open(Pdf, "_blank");
  };

  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <p className="text">fale com a nossa central:</p>

          <CtaWpp />

          <button className="rules" onClick={openPDF}>
            <p>Ver Regras</p>
          </button>

          <div className="hr"></div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <figure>
            <img src={logo_rodape} alt="Logo" />
          </figure>
        </div>
      </div>

      <div className="footer3">
        <div className="container">
          <p className="text">STP CLUB - Todos os direitos reservados</p>
        </div>
      </div>

      <div className="footer4">
        <div className="container">
          <p className="text">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
