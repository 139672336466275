import "./styles.css";

import wpp2 from "../../assets/wpp2.png";

const Wpp = () => {
  return (
    <a
      href="http://wa.me/+556281458113?text=Olá,%20Gostaria%20de%20saber%20mais"
      target="_blank"
      rel="noreferrer"
      id="wpp"
    >
      <figure>
        <img src={wpp2} alt="Ícone do Whatsapp" />
      </figure>
    </a>
  );
};

export default Wpp;
