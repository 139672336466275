import "./styles.css";

import CtaForm from "../../components/CTAForm";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">Como funciona?</h2>

          <p className="text">
            Veja como é simples e econômico abastecer com STP Club, em apenas 2
            passos:
          </p>

          <div className="item">
            <p className="number one">1</p>

            <p className="item_title">FAÇA A ASSINATURA DO STP CLUB</p>

            <p className="item_text">
              Clique no botão “quero assinar agora!” encontre no mapa um dos
              postos credenciados mais próximo de você para fazer a assinatura
              do STP Clube.
            </p>
          </div>

          <div className="item">
            <p className="number">2</p>

            <p className="item_title">abasteceça com desconto</p>

            <p className="item_text">
              Abasteça no posto onde realizou a assinatura, assim você terá
              acesso a descontos ilimitados toda vez que abastecer o veículo
              cadastrado. Além de receber mensalmente um Aditivo STP Flex de
              brinde.
            </p>
          </div>

          <CtaForm text={"QUERO ASSINAR AGORA!"} />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">
            <span>Dúvidas frequentes</span>
          </h2>

          <div className="faq">
            <details>
              <summary>QUEM PODE USUFRUIR DOS DESCONTOS?</summary>

              <p>
                O desconto só é aplicado ao veículo cadastrado, ou seja, 1
                assinatura equivale a 1 veículo.
              </p>
            </details>

            <details>
              <summary>SOMENTE EU POSSO ABASTECER?</summary>

              <p>
                Outros condutores do veículo cadastrados também podem abastecer,
                assinatura é feita por placa, não por condutor.
              </p>
            </details>

            <details>
              <summary>POSSO ABASTECER OUTRO VEÍCULO DE MINHA POSSE?</summary>

              <p>
                Não. Amenos que este segundo veículo também tenha uma assinatura
                STP ativa.
              </p>
            </details>

            <details>
              <summary>POSSO ABASTECER GALÕES?</summary>

              <p>
                Não é concedido o desconto referente à assinatura STP em caso de
                abastecimento com galões.
              </p>
            </details>

            <details>
              <summary>POSSO ABASTECER EM QUALQUER POSTO?</summary>

              <p>
                Não. Para garantir o seu desconto, você deve abastecer apenas no
                posto em efetuou a assinatura STP.
              </p>
            </details>

            <details>
              <summary>COMO FUNCIONA O PAGAMENTO DA ASSINATURA?</summary>

              <p>
                Pagamento deve ser feito mensalmente , no valor de R$ 59,90, via
                cartão de crédito, de forma recorrente.
              </p>
            </details>

            <details>
              <summary>COMO FUNCIONA O PAGAMENTO AO ABASTECER?</summary>

              <p>
                Os postos só aceitam pagamentos dos assinantes via pix.
                Certifique-se antecipadamente de ter saldo suficiente em seu app
                para realizar o pagamento no ato do abastecimento e garantir o
                seu desconto.
              </p>
            </details>
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <p className="text1">aproveite esta oportunidade por apenas:</p>
          <p className="text2">R$ 59,90/mês</p>
          <p className="text3">
            com pagamento no cartão de crédito/recorrência.
          </p>

          <CtaForm text={"ASSINE AGORA MESMO!"} />
        </div>
      </article>
    </section>
  );
};

export default About;
