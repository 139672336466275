import "./styles.css";

import Form from "../../components/Form";

const FormPage = () => {
  return (
    <section id="formPage">
      <article className="container">
        <h2 className="title">QUER SABER MAIS SOBRE O STP CLUB?</h2>

        <p className="text">Preencha o formulário, entraremos em contato:</p>

        <Form />
      </article>
    </section>
  );
};

export default FormPage;
