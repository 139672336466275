import React from "react";
import "./styles.css";

import logo_topo from "../../assets/logo_topo.png";
import wpp from "../../assets/wpp.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure className="top">
          <img src={logo_topo} alt="Logo" />
        </figure>

        <article className="bottom">
          <p className="text">Fale conosco:</p>

          <figure>
            <img src={wpp} alt="Ícone do Whatsapp" />
          </figure>

          <a
            href="http://wa.me/+556281458113?text=Olá,%20Gostaria%20de%20saber%20mais"
            target="_blank"
            rel="noreferrer"
          >
            <p className="number">(62) 8145-8113</p>
          </a>
        </article>
      </section>
    </nav>
  );
};

export default Menu;
