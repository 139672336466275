import "./styles.css";

import CtaForm from "../../components/CTAForm";
import CtaFormLink from "../../components/CTAFormLink";

import header from "../../assets/header.png";
import header3 from "../../assets/header3.png";

import video from "../../assets/enzo_falquete.mp4";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            Assine o STP Club e tenha <span> descontos exclusivos </span> <br />
            em combustíveis!
          </h1>

          <figure className="img">
            <img src={header} alt="STP Flex Treatment" />
          </figure>

          <p className="text">
            Abasteça com desconto de forma ilimitada e ganhe também 1 aditivo
            STP por mês.
          </p>

          <CtaFormLink />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h1 className="title">
            assista ao vídeo do <br />
            <span>CAMPEÃO BRASILEIRO COPA SHELL HYUNDAI HB20 2023🇧🇷 </span>
            <br /> Enzo Falquete.
          </h1>

          <video width="260" height="420" controls>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <h2 className="title">
            Garanta a partir de R$ 0,20 de desconto por litro com o STP Club.
          </h2>

          <figure>
            <img src={header3} alt="Brinde" />
          </figure>

          <p className="text1">aproveite esta oportunidade por apenas:</p>
          <p className="text2">R$ 59,90/mês</p>
          <p className="text3">
            com pagamento no cartão de crédito/recorrência.
          </p>

          <CtaForm text={"ASSINE AGORA MESMO!"} />
        </article>
      </section>
    </header>
  );
};

export default Header;
