import { useEffect, useState } from "react";
import "./styles.css";

const CtaForm = (props) => {
  const [text, setText] = useState("Conheça os postos participantes");

  useEffect(() => {
    if (props.text !== undefined) setText(props.text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button id="ctaForm">
      <a href="#form">
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaForm;
