import "./styles.css";

const CtaFormLink = (props) => {
  return (
    <button id="ctaFormLink">
      <a
        href="https://www.google.com/maps/d/u/0/edit?hl=pt-BR&mid=1FJFC5kjkFcDRjJs5XpC8T2lKkBdvSa4&ll=-13.075280677819318%2C-43.35970522817733&z=5"
        target="_blank"
        rel="noreferrer"
      >
        <p>CONHEÇA OS POSTOS PARTICIPANTES</p>
      </a>
    </button>
  );
};

export default CtaFormLink;
