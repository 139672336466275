import { useState } from "react";
import "./styles.css";

import iconwpp from "../../assets/iconwpp.png";

const CtaWpp = () => {
  const [text, setText] = useState("(62) 8145-8113");

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/+556281458113?text=Olá,%20Gostaria%20de%20saber%20mais"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={iconwpp} alt="Ícone do Whatsapp" />
        </figure>

        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
